import {
  BankOutlined,
  CameraOutlined,
  ContactsOutlined,
  CopyOutlined,
  CrownFilled,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  StarFilled,
  ThunderboltTwoTone,
  TrophyFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, Form, Input, Select, Tag } from 'antd';
import clsx from 'clsx';
import { FunctionComponent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';
import { UserHeaderT } from './types';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { copyTextToTheClipboard } from 'shared/utils/copyTextToTheClipboard';
const { Option } = Select;
const { TextArea } = Input;

export const UserHeader: FunctionComponent<UserHeaderT> = ({
  name,
  id = null,
  status,
  type,
  email,
  workNumber,
  code,
  setShowedModalName,
  defaultAccount,
  accountType,
  deleted_at = false,
  accountTypeFromQuery,
}) => {
  const showModalChangeNaming = useCallback(() => {
    setShowedModalName(true);
  }, [setShowedModalName]);

  const permissionGranted = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  });

  const iconByAccountType = (accType: string = '') => {
    switch (accType.toLocaleLowerCase()) {
      case APP_CONSTANS.ACCOUNT_TYPES.CUSTOMER.toLowerCase():
        return <ContactsOutlined />;

      case APP_CONSTANS.ACCOUNT_TYPES.PHOTOGRAPHER.toLowerCase():
        return <CameraOutlined />;

      case APP_CONSTANS.ACCOUNT_TYPES.INTERNAL.toLowerCase():
        return <ThunderboltTwoTone />;

      default:
        return <BankOutlined />;
    }
  };

  const accountTypeOptions = () =>
    Object.values(APP_CONSTANS.ACCOUNT_TYPES).map((accType) => ({
      value: accType,
      label: accType,
    }));
  const active = (status === 'Active' || status === 'active') && !deleted_at;

  return (
    <section className={styles.info}>
      <div className={styles.info__rates}>
        <Avatar
          className={styles.avatar}
          size={160}
          icon={
            type === 'Account' ? (
              iconByAccountType(accountType)
            ) : (
              <UserOutlined />
            )
          }
        />

        {/* {type === 'photographer' &&
                    <Form.Item name='rate'>
                        <Rate count={3} className={styles.info__rate}/>
                    </Form.Item>
                } */}
      </div>

      <div className={styles.info__user}>
        <div className={styles.info__user__personal}>
          <div className={styles.nameBlock}>
            {type === 'Account' ? (
              <Form.Item
                name="name"
                className={styles.textareaAccountName}
                initialValue={name}
              >
                <TextArea
                  spellCheck={false}
                  variant="borderless"
                  className={styles.accName}
                  value={name}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </Form.Item>
            ) : (
              <>
                <h3 className={styles.name}>{name}</h3>
                <Button
                  type="text"
                  className={styles.editNameButton}
                  onClick={showModalChangeNaming}
                >
                  <EditOutlined className={styles.editNameButtonIcon} />
                </Button>
              </>
            )}
          </div>

          <Tag className={styles.status} color={active ? 'green' : 'red'}>
            {deleted_at ? 'Deleted' : status}
          </Tag>

          {id && (
            <p className={styles.id}>
              {type === 'Account' ? 'Account ID: ' : 'ID: '}
              {id}
            </p>
          )}
          {type === 'Account' && code && (
            <p className={styles.id}>Account code: {code}</p>
          )}
          {type === 'Account' && accountType && (
            <div className={styles.accountTypeInputContainer}>
              <p className={styles.id}>Account type:&nbsp;</p>
              <Form.Item
                name="type"
                className={clsx(
                  styles.customerType,
                  styles.selectAccountTypeFormItem,
                  'userSelect'
                )}
                style={{ margin: 0 }}
                initialValue={accountTypeFromQuery}
              >
                <Select
                  variant="borderless"
                  popupClassName={styles.selectDropdownType}
                  placeholder={
                    <span
                      style={
                        accountType !== accountTypeFromQuery
                          ? { color: 'red' }
                          : {}
                      }
                    >
                      Type not set
                    </span>
                  }
                  options={accountTypeOptions()}
                />
              </Form.Item>
            </div>
          )}
        </div>

        {type !== 'Account' && (
          <div className={styles.info__user__level}>
            <Form.Item
              name="type"
              className={clsx(
                type === 'customer' && styles.customerType,
                styles.selectTypeFormItem,
                'userSelect'
              )}
            >
              <Select
                disabled={!permissionGranted}
                className={styles.select}
                variant="borderless"
                popupClassName={styles.selectDropdownType}
              >
                <Option value="customer">Customer</Option>
                <Option value="photographer">Photographer</Option>
                <Option value="superuser">Superuser</Option>
                <Option value="editor">Editor</Option>
                <Option value="support">Support</Option>
                <Option value="finance">Finance</Option>
                <Option value="sales">Sales</Option>
              </Select>
            </Form.Item>

            {type === 'customer' && defaultAccount && (
              <>
                <Link to={`/accounts/all/${defaultAccount.id}`}>
                  {defaultAccount.name}
                </Link>

                <Divider type="vertical" className={styles.divider} />
              </>
            )}

            {(type === 'photographer' || type === 'superuser') && (
              <>
                <Divider type="vertical" className={styles.divider} />

                <Form.Item
                  name="level"
                  className={clsx(
                    styles.selectType,
                    styles.selectLevel,
                    'userSelect'
                  )}
                >
                  <Select
                    variant="borderless"
                    className={clsx(styles.select, styles.rateSelect)}
                    popupClassName={styles.selectDropdownType}
                  >
                    <Option value="Unrated">
                      <StarFilled
                        style={{ color: '#FFC7D0', marginRight: '8px' }}
                      />
                      Unrated
                    </Option>
                    <Option value="Enthusiast">
                      <TrophyFilled
                        style={{ color: '#F7941D', marginRight: '8px' }}
                      />
                      Enthusiast
                    </Option>
                    <Option value="Professional">
                      <CrownFilled
                        style={{ color: '#FCE517', marginRight: '8px' }}
                      />
                      Professional
                    </Option>
                  </Select>
                </Form.Item>
              </>
            )}

            {/* <Divider type="vertical" className={styles.divider}/> */}

            {/* {
                                      type === 'photographer'
                                      ? <a href='#'>{email}</a>
                                      : <p className={styles.customer__date}>Last seen: {lastSeenDate}</p>
                                  } */}
            {/* <div className={styles.email}>
                                      <Divider type="vertical" className={styles.divider}/>
                                  
                                      <a href='#'>{email}</a>
                                  </div> */}
          </div>
        )}

        <div className={styles.info__user__contact}>
          <Form.Item
            name={type === 'Account' ? 'emails' : 'email'}
            className={clsx(styles.form__input, styles.form__email)}
            {...(type === 'Account' && { initialValue: email })}
            // style={{ maxWidth: `${email.length * 11 + 40}px` }}
          >
            <Input
              className={styles.input}
              prefix={<MailOutlined />}
              addonAfter={
                <CopyOutlined
                  onClick={(e) => copyTextToTheClipboard(e, email)}
                />
              }
            />
          </Form.Item>
          <Form.Item
            name={type === 'Account' ? 'phones' : 'phone'}
            className={clsx(styles.form__input, styles.form__phone)}
            {...(type === 'Account' && { initialValue: workNumber })}
          >
            <Input
              className={styles.input}
              prefix={<PhoneOutlined />}
              addonAfter={
                <CopyOutlined
                  onClick={(e) => copyTextToTheClipboard(e, workNumber)}
                />
              }
            />
          </Form.Item>
        </div>
      </div>
    </section>
  );
};
