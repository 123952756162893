export const userPrefsIdToDrawer = [
  // 'a4917f4f-a949-4038-b9cb-e946ebe660b8',
  // '94291a54-d88d-4ff2-be70-b77b7c46746a',
  'a11e1d21-50d7-4058-a31e-42135f09c218', //Stabilization
  'a398164f-fa18-4606-b77e-787ad95e7d9b', //Stabilization Equipment
  // 'a13e8a1f-d133-431b-8304-eb402ea91c40', //Drone license
];

export const userTitles: { [key: string]: string } = {
  'a032c8a0-d190-4db5-8f5b-6ba8a3761e3e': 'Jobs',
  'a13e8a1f-d133-431b-8304-eb402ea91c40': 'Drone license',
  'a11e1d21-50d7-4058-a31e-42135f09c218': 'Equipment',
  'af26a292-cf2e-4d22-b923-e409496dfdeb': 'Payout method',
  'e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2': 'Traits',
};

export const accountPrefsIdToDrawer = [
  'ae3ef777-6253-4c93-ab3c-584e61a3ac07',
  'ac3e11c9-f8ce-4b75-ac65-b717c8de5df8',
  'ab9ca8c7-0563-4ff1-98f1-2a14a263464c',
  '715c320d-017f-4f22-a9e8-ebe6d42ba0d0',
];
