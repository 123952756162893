import { Button, Form, Input, Select, Space, message } from 'antd';
import { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { ModalWindow } from '../../ui_components/ModalWindow';
import {
  CountriesObjT,
  CountryRegionsObjT,
  FieldsToBackProps,
  ModalUserAddressProps,
  RegionsByCountry,
} from './types';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { checkZipcodeByState } from '../../../shared/utils/checkZipcodeByState';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryNames } from '../../../api/queryNames';
import useGetData from '../../../api/useGetData';
import { usePostData } from '../../../api/usePostData';
const { Option } = Select;

export const ModalUserAddress: FunctionComponent<ModalUserAddressProps> = ({
  mode,
  visible,
  setHidden,
  address,
  userId = null,
  accountId = null,
  setRefetchData,
  addressType,
}) => {
  const [userAddressForm] = Form.useForm();
  const [sortedCountries, setSortedCountries] = useState<CountriesObjT[]>([]);
  const [sortedCountryRegions, setSortedCountryRegions] =
    useState<RegionsByCountry>([]);
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    data: dataCountries,
    error: errorCountries,
    refetch: refetchCountries,
  } = useQuery({
    queryKey: [queryNames.REFERENCE_getCountries],
    meta: { token, getAllData },
    queryFn: useGetData,
  });

  const {
    isLoading: isLoadingCountryRegions,
    isError: isErrorCountryRegions,
    data: dataCountryRegions,
    error: errorCountryRegions,
    refetch: refetchCountryRegions,
  } = useQuery({
    queryKey: [queryNames.REFERENCE_getCountryRegions],
    meta: { token, getAllData },
    queryFn: useGetData,
  });

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: (error, variables, context) => {
      message.error('Network Error');
    },
    onSuccess: (data, variables, context) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      setRefetchData(true);
      message.success('All data saved');
      userAddressForm.resetFields();
    },
  });

  useEffect(() => {
    if (address) {
      const {
        name,
        country,
        region,
        city,
        zipcode,
        address1,
        address2,
        address3,
      } = address;
      userAddressForm.setFieldsValue({
        name,
        country,
        region,
        city,
        zipcode,
        address1,
        address2,
        address3,
      });
    } else {
      userAddressForm.resetFields();
    }
  }, [userAddressForm, address]);

  const sortData = useCallback((data: any, key: string) => {
    let sortedData = [...data].sort((dataPrev: any, dataNext: any) =>
      dataPrev[key].localeCompare(dataNext[key])
    );
    return sortedData;
  }, []);

  const filteredRegionByCountryCode = useCallback(
    (regions: CountryRegionsObjT[], value: string) => {
      const filteredByCountryCode = regions.filter(
        (region: CountryRegionsObjT) => region.country_code === value
      );
      setSortedCountryRegions(sortData(filteredByCountryCode, 'region_name'));
    },
    [sortData]
  );

  useEffect(() => {
    if (dataCountries && dataCountries.hasOwnProperty('data')) {
      setSortedCountries(sortData(dataCountries.data, 'name'));
    }
  }, [dataCountries, sortData]);

  useEffect(() => {
    if (dataCountryRegions && dataCountryRegions.hasOwnProperty('data')) {
      filteredRegionByCountryCode(
        dataCountryRegions.data,
        address ? address.country : ''
      );
    }
  }, [filteredRegionByCountryCode, dataCountryRegions, address]);

  const changeCountry = useCallback(
    (value: string) => {
      filteredRegionByCountryCode(dataCountryRegions.data, value);
      userAddressForm.resetFields(['region', 'zipcode']);
      userAddressForm.setFieldValue('region', '');
      userAddressForm.setFieldValue('zipcode', '');
    },
    [filteredRegionByCountryCode, dataCountryRegions, userAddressForm]
  );

  const changeRegion = useCallback(
    (value: string) => {
      userAddressForm.resetFields(['zipcode']);
      userAddressForm.setFieldValue('zipcode', '');
    },
    [userAddressForm]
  );

  const submitAddressForm = useCallback(
    (allFields: FieldsToBackProps) => {
      if (addressType.includes('user') && userId) {
        allFields.user_id = userId;
        if (mode === 'Change' && address) {
          allFields.id = address.id;
          mutation.mutate({
            data: allFields,
            token,
            otherProps: 'user_addresses',
            method: 'PUT',
          });
        } else {
          mutation.mutate({
            data: allFields,
            token,
            otherProps: 'user_addresses',
          });
        }
      } else if (addressType.includes('account') && accountId) {
        if (mode === 'Change' && address) {
          allFields.account_ids = [accountId];
          allFields.id = address.id;
          mutation.mutate({
            data: allFields,
            token,
            otherProps: 'account_addresses',
            method: 'PUT',
          });
        } else {
          allFields.account_id = accountId;
          mutation.mutate({
            data: allFields,
            token,
            otherProps: 'account_addresses',
          });
        }
      }
      setHidden(false);
    },
    [address, addressType, setHidden, mode, userId, accountId, mutation, token, userAddressForm]
  );

  return (
    <ModalWindow
      title={`${mode} address`}
      visible={visible}
      width="700px"
      footer={null}
      onCancel={() => setHidden(false)}
    >
      <Form
        form={userAddressForm}
        layout="vertical"
        onFinish={submitAddressForm}
        className={styles.addressForm}
      >
        <Form.Item
          label="Address name"
          name="name"
          initialValue={address ? address.name : ''}
          required={true}
          className={styles.formBlock}
          rules={[
            {
              required: true,
              message: 'Please provide address name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          initialValue={address ? address.country : ''}
          required={true}
          className={styles.formBlock}
          rules={[
            {
              required: true,
              message: 'Please choose country!',
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            onChange={changeCountry}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {sortedCountries &&
              sortedCountries.map((country: CountriesObjT) => (
                <Option value={country.code} key={country.name}>
                  {country.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Region"
          name="region"
          initialValue={address ? address.region : ''}
          required={true}
          className={styles.formBlock}
          rules={[
            {
              required: true,
              message: 'Please choose region!',
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            onChange={changeRegion}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {sortedCountryRegions &&
              sortedCountryRegions.map((region: CountryRegionsObjT) => (
                <Option value={region.region_code} key={region.region_name}>
                  {region.region_name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="ZIP code"
          name="zipcode"
          initialValue={address ? address.zipcode : ''}
          required={true}
          className={styles.formBlock}
          dependencies={['region', 'country']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length > 0) {
                  if (getFieldValue('country') === 'US') {
                    const stateByZipcode = checkZipcodeByState(value);
                    if (stateByZipcode === getFieldValue('region')) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error('Zip code is not exist in this region.')
                      );
                    }
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.reject(new Error('Please provide zip code!'));
                }
              },
            }),
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          initialValue={address ? address.city : ''}
          required={true}
          className={styles.formBlock}
          rules={[
            {
              required: true,
              message: 'Please provide city!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Address Line 1"
          name="address1"
          initialValue={address ? address.address1 : ''}
          required={true}
          className={styles.formBlock}
          rules={[
            {
              required: true,
              message: 'Please provide address!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Address Line 2"
          name="address2"
          initialValue={address ? address.address2 : ''}
          className={styles.formBlock}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Address Line 3"
          name="address3"
          initialValue={address ? address.address3 : ''}
          className={styles.formBlock}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ margin: 0 }}>
          <Space className={styles.footer}>
            <Button htmlType="button" onClick={() => setHidden(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save changes
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ModalWindow>
  );
};
