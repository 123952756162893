import { ContentTags } from 'components/Tags/ContentTags';
import styles from './styles.module.scss';
import { TimeTooltip } from 'components/ui_components/TimeTooltip';
export interface ModalDrawerExifProps {
  contentData: {
    type?: any;
    size?: any;
    date?: any;
    dimensions?: any;
    resolution?: any;
    camera?: any;
    aperture?: any;
    focalLength?: any;
    iso?: any;
    shutter?: any;
    orientation?: any;
    duration?: any;
    contentFlags?: any;
    createdDate?: any;
    timezone: string;
  };
  fileName: {
    original?: string;
    content?: string;
  };
  exifData: any;
  id: string;
}

export const ModalDrawerExif: React.FC<ModalDrawerExifProps> = ({
  contentData,
  exifData,
  fileName,
  id,
}) => {
  const {
    type,
    size,
    date,
    dimensions,
    aperture,
    camera,
    duration,
    focalLength,
    iso,
    orientation,
    resolution,
    shutter,
    createdDate,
    timezone,
  } = contentData || {};
  const { original: originalName, content: contentName } = fileName || {};
  const contentFlag: number = contentData?.contentFlags;
  const editedFlag: number = contentFlag && contentFlag & 16;
  return (
    <div className={styles.exif}>
      {contentName && originalName && (
        <div>
          <p className={styles.exif__title} style={{ margin: '12px 0 12px' }}>
            {contentName}
          </p>
          {originalName && (
            <span className={styles.exif__label}>({originalName})</span>
          )}
          {editedFlag === 16 && <p className={styles.badgeEdited}>Edited</p>}
        </div>
      )}
      <ContentTags contentId={id} topDivider bottomDivider />

      <p>
        <span className={styles.exif__label}>MIME Type: </span>
        <span className={styles.exif__value}>{type || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>File Size: </span>
        <span className={styles.exif__value}>
          {(size / 1000000).toFixed(1)} MB
        </span>
      </p>
      <p>
        <span className={styles.exif__label}>Dimensions: </span>
        <span className={styles.exif__value}>{dimensions || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Resolution: </span>
        <span className={styles.exif__value}>{resolution || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Camera: </span>
        <span className={styles.exif__value}>{camera || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Aperture: </span>
        <span className={styles.exif__value}>{aperture || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Focal Length: </span>
        <span className={styles.exif__value}>{focalLength || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>ISO: </span>
        <span className={styles.exif__value}>{iso || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Shutter: </span>
        <span className={styles.exif__value}>{shutter || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Orientation: </span>
        <span className={styles.exif__value}>{orientation || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Duration: </span>
        <span className={styles.exif__value}>{duration || '-'}</span>
      </p>
      <p>
        <span className={styles.exif__label}>Take Date and Time: </span>
        <span className={styles.exif__value}>
          {date ? (
            <TimeTooltip
              date={date}
              timezone={timezone}
              timezoneDisplayPreset={'long'}
            />
          ) : (
            '-'
          )}
        </span>
      </p>
      <p>
        <span className={styles.exif__label}>Created Date: </span>
        <span className={styles.exif__value}>
          {createdDate ? (
            <TimeTooltip
              date={createdDate}
              timezone={timezone}
              timezoneDisplayPreset={'long'}
            />
          ) : (
            '-'
          )}
        </span>
      </p>
      <p className={styles.exif__title}>Meta data:</p>
      <ul className={styles.exif__menu}>
        {exifData ? (
          Object.entries(JSON.parse(exifData)).map(
            (exifItem: any, i: number) => (
              <li key={i}>
                <span className={styles.exif__value}>{exifItem[0]}:</span>
                <span className={styles.exif__value}>{exifItem[1]}</span>
              </li>
            )
          )
        ) : (
          <p>No EXIF data</p>
        )}
      </ul>
    </div>
  );
};
