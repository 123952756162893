import { Button, Drawer, Tabs } from 'antd';
import styles from './styles.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useState } from 'react';
import { ModalDrawerExif } from '../ModalDrawerExif';
import { ModalDrawerURLS } from '../ModalDrawerURLS';
import { Comments } from 'components/ui_components/Comments';

export const ModalContentDrawer: React.FC<any> = ({
  content,
  isCarousel,
  commentSection = {},
  containerCurrentWidth,
}) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const { meta } = content || {};

  const tabItems = [
    {
      label: 'EXIF data',
      key: '1',
      children: (
        <ModalDrawerExif
          contentData={{
            type: content?.content_type,
            size: content?.content_size || 0,
            date: content?.taken_at,
            aperture: meta?.aperture,
            camera: meta?.camera,
            dimensions: meta?.['full_size'],
            duration: meta?.duration,
            focalLength: meta?.['focal_length'],
            iso: meta?.['iso_speed'],
            orientation: meta?.orientation,
            resolution: meta?.resolution,
            shutter: meta?.shutter,
            contentFlags: content?.content_flags,
            createdDate: content?.created_at,
            timezone: content?.order?.timezone,
          }}
          fileName={{
            original: content?.['original_name'],
            content: content?.['content_filename'],
          }}
          exifData={content?.content_exif}
          id={content?.id}
        />
      ),
    },
    {
      label: 'Comments',
      key: '2',
      children: (
        <Comments
          hasInternalTab={true}
          photographerId={commentSection?.photographerId}
          jobId={commentSection?.jobId}
          customerId={commentSection?.customerId}
          orderId={commentSection?.orderId}
          commentStartText={`Content ${content?.content_filename} `}
          objectType="fulfilment_content"
          objectId={content?.id}
          relatives={[content?.order_id]}
        />
      ),
    },
    {
      label: 'S3',
      key: '3',
      children: (
        <ModalDrawerURLS
          originalUrl={content?.original_url}
          releaseUrl={content?.release_url}
          contentUrl={content?.content_url}
        />
      ),
    },
  ];

  return (
    <div
      className={clsx(styles.blockDrawer, isCarousel && styles.carouselDrawer)}
    >
      <Button
        shape="circle"
        icon={<LeftOutlined />}
        onClick={showDrawer}
        className={clsx(
          styles.blockDrawer__btnDrawer,
          styles.blockDrawer__showBtnDrawer
        )}
        disabled={false}
      />
      <Drawer
        placement="right"
        onClose={closeDrawer}
        open={open}
        className={styles.drawer}
        rootClassName={clsx('drawerRootContentModal')}
        maskStyle={{ background: 'transparent' }}
        closable={false}
        width={containerCurrentWidth < 480 ? containerCurrentWidth : 480}
      >
        <Button
          shape="circle"
          icon={<RightOutlined />}
          onClick={closeDrawer}
          className={clsx(
            styles.blockDrawer__btnDrawer,
            styles.blockDrawer__closeBtnDrawer
          )}
          disabled={false}
        />

        <Tabs
          defaultActiveKey="1"
          centered
          items={tabItems}
          className={clsx('drawerTabsContentModal')}
        />
      </Drawer>
    </div>
  );
};
