import { Input, message, Select } from 'antd';
import { SearchProps } from './types';
import { FC, useState } from 'react';

type SearchByComplicationsT = {
  setFilters?: any;
};

export const SearchByComplications: FC<SearchByComplicationsT> = ({
  setFilters,
}) => {
  const [field, setField] = useState<string | undefined>();
  const [value, setValue] = useState<string>('');

  const onSearch: SearchProps['onSearch'] = (searchValue) => {
    if (!searchValue) {
      message.warning('Please enter search value');
      return;
    }
    if (!field) {
      message.warning('Please select field');
      return;
    }
    if (searchValue && field) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach(
        (param) => param.startsWith('prices') && urlParams.delete(param)
      );
      setFilters &&
        setFilters((prevFilter: { [key: string]: string[] | undefined }) => {
          const newFilter = {
            [`prices%3A%3A${field}%3A%3A${searchValue}`]: {
              isJson: true,
              key: [''],
            },
          };
          if (!prevFilter) {
            return newFilter;
          }
          for (const key of Object.keys(prevFilter)) {
            if (key.startsWith('prices')) {
              delete prevFilter[key];
            }
          }
          return {
            ...prevFilter,
            ...newFilter,
          };
        });
    }
    setValue('');
    setField(undefined);
  };

  return (
    <Input.Search
      styles={{
        affixWrapper: {
          height: '40px',
        },
      }}
      addonBefore={
        <Select
          style={{ width: '100px' }}
          placeholder={'[field]'}
          onChange={setField}
          value={field}
        >
          <Select.Option value="name">Name</Select.Option>
          <Select.Option value="value">Value</Select.Option>
          <Select.Option value="input_value">Date</Select.Option>
        </Select>
      }
      placeholder="Search by complication"
      onChange={(e) => setValue(e.target.value)}
      onSearch={onSearch}
      value={value}
    />
  );
};
