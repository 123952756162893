let basePaymentsUrl = process.env.REACT_APP_PAYMENTS
  ? `${process.env.REACT_APP_PAYMENTS}/v2`
  : `http://localhost:3004/v2`;
let baseFilesUrl = process.env.REACT_APP_FILES
  ? process.env.REACT_APP_FILES
  : `http://localhost:3002`;
let baseApiUrl = process.env.REACT_APP_ENV_URL
  ? process.env.REACT_APP_ENV_URL.replace('/v2', '/v1')
  : 'http://localhost:3001/v1';
let baseApiUrlV2 = process.env.REACT_APP_ENV_URL
  ? process.env.REACT_APP_ENV_URL
  : 'http://localhost:3001/v2';
let baseOldAdmin = process.env.REACT_APP_OLD_ADMIN
  ? process.env.REACT_APP_OLD_ADMIN
  : 'http://localhost:3000/';
let baseLoginUrl = `${baseOldAdmin}user/login`;
let basePhotographerUI = process.env.REACT_APP_REACT
  ? process.env.REACT_APP_REACT
  : 'http://localhost:3040/';

let baseNotifyUrl = process.env.REACT_APP_NOTIFY
  ? process.env.REACT_APP_NOTIFY
  : 'http://localhost:3003/v2';

export enum PhotographerRate {
  UNRATED = 'Unrated',
  ENTHUSIAST = 'Enthusiast',
  PROFESSIONAL = 'Professional',
}

export enum JobType {
  PHOTO = 'photo',
  VIDEO = 'video',
  DRONE = 'drone',
}

const updatedLimit =
  window.innerHeight > 2000 ? 35 : window.innerHeight > 1500 ? 25 : 20;

export const APP_CONSTANS = {
  COMPANY_NAME: 'FotoFetch',
  DEFAULT_TITLE: 'FotoFetch - commercial photography with the best prices',
  VERSION_ADMIN: '1.3.0',
  GLOBAL_FETCH_LIMIT: updatedLimit,
  BASE_API_URL_V2: baseApiUrlV2,
  BASE_API_URL: baseApiUrl,
  BASE_PAYMENTS_URL: basePaymentsUrl,
  BASE_FILES_URL: baseFilesUrl,
  BASE_OLD_ADMIN_URL: baseOldAdmin,
  BASE_LOGIN_URL: baseLoginUrl,
  BASE_APP_REACT: basePhotographerUI,
  BASE_NOTIFY_URL: baseNotifyUrl,
  SESSION_LIFE_IN_MINUTES: 60,
  ONE_MINUTE_IN_MS: 60000,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  GOOGLE_MAP_NONCE: 'FF-Map-nonce',
  USER_FULLNAME: 'USER_FULLNAME',
  LS_TOKEN: 'FF_SU_TOKEN',
  LS_SESSION_EXP: 'FF_SU_SESSION_EXP',
  LS_VL_TOKEN: 'FF_SU_VL_TOKEN',
  TOKEN_URL: 'auth_token',
  ORDER: 'order',
  ORDERS: 'orders',
  JOB: 'job',
  JOBS: 'jobs',
  INVOICE: 'invoice',
  INVOICES: 'invoices',
  PURCHASE: 'purchase',
  PURCHASES: 'purchases',
  FULFILMENT: 'fulfilment',
  TEMPLATE: 'template',
  DEFAULT_FOTOFETCH_ACCOUNT: 'dd0848f5-fd4f-4080-8f3b-65a94bad0460',
  CURRENCY_USA_USD_GUID: 'bae0ad89-94bb-ff26-24b4-e2c1b8b5cb7f',
  ORDER_STATUSES_GUID_BY_CODE: {
    DRAFT_ORDER: '563a3e9d-e756-43b4-bc21-3e90ecaa7a0c',
    TEMPLATE_ORDER: '7d664841-fefe-440d-a6e7-7454371904ee',
    SUBMITTED_ORDER: '4e1f8868-06a3-4614-934f-0f7813d99592',
    PENDING_ORDER: 'dbe718a1-6774-4bb3-ae71-d942c11bc53f',
    APPROVED_ORDER: '2d64523c-8123-4993-8f15-484bd29968ab',
    REJECTED_ORDER: 'feaff639-f8e0-4c9b-83a7-3442dffaaa9f',
    PUBLISHED_ORDER: '351fe340-4615-40df-98b2-23097e779f8f',
    ACCEPTED_ORDER: '422068bf-8945-49fe-818a-574e6a4b0ada',
    COMPLETED_ORDER: '830e7ac6-f880-43c5-97e9-c3e58a399779',
    CANCELLED_ORDER: 'db6f747a-8e50-495f-b575-e31bed45ea2e',
    EXPIRED_ORDER: '865bbcaa-305e-41a5-bd7a-080ca6e5c3af',
    AUTHORIZE_PENDING: '0e229179-44d9-46fe-9ff9-bc37d6df316e',
  } as Record<string, string>,
  ORDER_STATUSES_BY_GUID: {
    '563a3e9d-e756-43b4-bc21-3e90ecaa7a0c': 'Draft',
    '7d664841-fefe-440d-a6e7-7454371904ee': 'Template',
    '4e1f8868-06a3-4614-934f-0f7813d99592': 'Submitted',
    'dbe718a1-6774-4bb3-ae71-d942c11bc53f': 'Pending',
    '2d64523c-8123-4993-8f15-484bd29968ab': 'Approved',
    'feaff639-f8e0-4c9b-83a7-3442dffaaa9f': 'Rejected',
    '351fe340-4615-40df-98b2-23097e779f8f': 'Published',
    '422068bf-8945-49fe-818a-574e6a4b0ada': 'Accepted',
    '830e7ac6-f880-43c5-97e9-c3e58a399779': 'Completed',
    'db6f747a-8e50-495f-b575-e31bed45ea2e': 'Cancelled',
    '865bbcaa-305e-41a5-bd7a-080ca6e5c3af': 'Expired',
  } as Record<string, string>,
  FULFILMENT_STATUSES_GUID_BY_CODE: {
    ACCEPTED_JOB: 'c90b48e3-2b6d-401e-a828-780b6e00d54d',
    SUBMITTED_JOB: 'c600cbae-84e9-4409-90e2-d2caab303498',
    PENDING_JOB: 'bf65bb1f-b8c9-461a-9d20-e88dd2254506',
    REJECTED_JOB: 'c39f2f70-b76e-4b09-824c-638f55433c68',
    RETURNED_JOB: 'c1a0f17f-7a7d-49d2-934e-7c4b9fa81102',
    COMPLETED_JOB: 'c14f210e-fc32-416b-a8d5-76dbe317888f',
    DECLINED_JOB: 'c10ee3cb-a927-4d32-9100-cb83a32da174',
    CANCELLED_JOB: 'b72cc1ae-f9d7-4bfe-9246-52476ed6d7ee',
    EXPIRED_JOB: 'c070ca57-827b-4f3d-8022-37baaa0aaffa',
  } as Record<string, string>,
  TRAIT_LABEL_BY_VALUE: {
    '0': 'Not set',
    '1': 'No',
    '3': 'Good',
    '5': 'Excellent',
  } as Record<string, string>,
  FULFILMENT_STATUSES_BY_GUID: {
    'c90b48e3-2b6d-401e-a828-780b6e00d54d': 'Accepted',
    'c600cbae-84e9-4409-90e2-d2caab303498': 'Submitted',
    'bf65bb1f-b8c9-461a-9d20-e88dd2254506': 'Pending',
    'c39f2f70-b76e-4b09-824c-638f55433c68': 'Rejected',
    'c1a0f17f-7a7d-49d2-934e-7c4b9fa81102': 'Returned',
    'c14f210e-fc32-416b-a8d5-76dbe317888f': 'Completed',
    'c10ee3cb-a927-4d32-9100-cb83a32da174': 'Declined',
    'b72cc1ae-f9d7-4bfe-9246-52476ed6d7ee': 'Cancelled',
    'c070ca57-827b-4f3d-8022-37baaa0aaffa': 'Expired',
  } as Record<string, string>,
  PURCHASE_STATUSES_BY_GUID: {
    '3980d346-4eba-4dec-a8ad-0e55f21c79e6': 'Not Paid',
    'fb206ee4-5464-4d06-9145-720f02c358df': 'Pending',
    'c93e4490-f1c5-45ae-bd7e-bf4b627f91f4': 'Payed',
    '00080873-4a62-4c02-9d28-b4918d7b64c9': 'Partial payed',
    '4199107b-90eb-415c-bd32-e8c1b3247e1a': 'Cancelled',
    '7ec4baa5-93f6-4fdb-9f72-98aa210da81c': 'Failed',
  } as Record<string, string>,
  PURCHASE_STATUSES_BY_NAME: {
    NotPaid: '3980d346-4eba-4dec-a8ad-0e55f21c79e6',
    Pending: 'fb206ee4-5464-4d06-9145-720f02c358df',
    Payed: 'c93e4490-f1c5-45ae-bd7e-bf4b627f91f4',
    PartialPayed: '00080873-4a62-4c02-9d28-b4918d7b64c9',
    Cancelled: '4199107b-90eb-415c-bd32-e8c1b3247e1a',
    Failed: '7ec4baa5-93f6-4fdb-9f72-98aa210da81c',
  } as Record<string, string>,
  INVOICE_STATUSES_BY_NAME: {
    Draft: 'c0ced139-af58-4b3c-8795-164f1e820c06',
    NotPaid: '0f4d90ad-9582-4aa1-870e-65ba54bea0cd',
    PartialPayed: '94b3c564-d7c5-48bf-b623-9cdd481c1e73',
    Payed: '881826b5-eccf-4e05-91a1-4ef9295872cc',
    Refunded: 'f207ae93-75cb-4a5e-8c83-9cb88d61e9dd',
    Completed: '2C830e7ac6-f880-43c5-97e9-c3e58a399779',
  } as Record<string, string>,
  ACCOUNT_TYPES: {
    PHOTOGRAPHER: 'Photographer',
    CUSTOMER: 'Customer',
    INTERNAL: 'Internal',
  } as Record<string, string>,
  USER_TYPES: {
    PHOTOGRAPHER: 'photographer',
    CUSTOMER: 'customer',
    SUPERUSER: 'superuser',
    SUPPORT: 'support',
    FINANCE: 'finance',
    SALES: 'sales',
    EDITOR: 'editor',
  } as Record<string, string>,
  ACCOUNT_PREFS_CODE: {
    DefaultPay: 'a6a3f067-7629-465e-9487-ac2fd7a0a710',
    USE_PAYPAL: 'a99d34dd-af40-45c5-b342-f594517f83bd',
    USE_VENMO_ID: 'a8612805-f592-449c-a6e5-96f7b780911c',
    USE_VENMO_NO: 'a81ce075-8b13-498f-8906-e2e7d183e38f',
  } as Record<string, string>,
  USER_PREFS_ID: {
    PhotographerLevel: 'a3cc8111-d107-49a8-a5e8-4bce9b1cf341',
    HasEquipment: 'a398164f-fa18-4606-b77e-787ad95e7d9b',
    CameraStabilization: 'a11e1d21-50d7-4058-a31e-42135f09c218',
    DroneLicence: 'a1e677d3-252e-48ef-a794-c57b521d54aa',
    DroneLicenceIssued: 'a162356e-6436-4b5c-96f1-29bb0ca2604b',
    DroneLicenceNumber: 'a13e8a1f-d133-431b-8304-eb402ea91c40',
    MaxJobCount: 'a032c8a0-d190-4db5-8f5b-6ba8a3761e3e',
  } as Record<string, string>,
  NOTIFICATIONS: {
    PHOTOGRAPHER: {
      STATUSES: {
        NotifyTypePhotographerJobRejectedByEditor:
          '865d5030-c981-400f-b678-b9a8ccbd4270',
        NotifyTypePhotographerJobReturnedByEditor:
          '85ebfc17-e6de-42b8-b8d4-d1baf4aced79',
        NotifyTypePhotographerJobCompleted:
          '85739016-c464-4c00-b767-eea5f8eecf12',
        NotifyTypePhotographerJobCanBeSubmitted:
          '8553c946-d9b6-44b6-8025-d784f242eb38',
        NotifyTypePhotographerJobPaid: '83008119-31cf-4f72-9d1c-2e9b7a9508f9',
        NotifyTypePhotographerOrderTimeout:
          '90fc2403-515d-41f6-b282-30ae7c8bde7a',
      } as Record<string, string>,
      COMMUNICATIONS: {
        NotifyTypePhotographerNewCommentFromEditor:
          '8a8fa266-cc56-45b7-b953-67768813695a',
        NotifyTypePhotographerJobAssignedToUser:
          '896e8667-fe17-4197-9292-f66159e7db17',
        NotifyTypePhotographerAboutNewJobs:
          '81450748-38a0-4ea0-ac53-046e61daa099',
        NotifyTypePhotographerRequestApproved:
          '7d47d758-81f7-41cc-8d89-fe153e7fd548',
        NotifyTypePhotographerRequestRejected:
          '7bbba837-9145-410b-a1bc-abe9d3dd419e',
        NotifyTypeUserRequestTimeExtension:
          '7b931b3a-acd1-4c39-b368-0f107038dc13',
        NotifyTypeUserRequestLevelUpgrade:
          '7a5f77b8-a8bc-4a70-b094-1b0287e89235',
        NotifyTypeUserRequestDroneLicense:
          '7877c89d-4f9f-420e-ba8c-eff1e907d318',
      } as Record<string, string>,
    },
    CUSTOMER: {
      STATUSES: {
        NotifyTypeCustomerOrderCompleted:
          '90b184a9-e4d9-4166-a117-a69b25cb9b4b',
        NotifyTypeCustomerOrderApproved: '8f8323cf-4cf9-4136-a8cb-5f9d27988ac1',
        NotifyTypeCustomerOrderRejected: '8c2261cd-7478-48c7-876d-a02bc258fd76',
        NotifyTypeCustomerOrderPublished:
          '8e8c9727-70c9-4c8a-8329-4fe17b004215',
        NotifyTypeCustomerOrderCancelled:
          '83829ec8-6051-4f12-a611-bc8e2173399b',
        NotifyTypeCustomerOrderExpired: '8c0bce5e-c748-4f3a-a070-bdb1534381c8',
        NotifyTypeCustomerOrderRejectedByEditor:
          '739b3903-4cf2-4b34-b708-a9ed2bcbbdeb',
        NotifyTypeCustomerOrderTimeout: '93617b3c-f131-4f6c-9737-72c333bedaf8',
        NotifyTypeCustomerOrderStatusChanged:
          '9203fd0c-2511-4eca-be6b-270ebaaecc3a',
      } as Record<string, string>,
      COMMUNICATIONS: {
        NotifyTypeCustomerNewCommentFromEditor:
          '8b7a8a43-e844-45e0-8d6d-61e59f1f6a0f',
        NotifyTypeUserZipArchiveCreated: '74fb954f-1b6e-44ee-9baf-db3be3c7c642',
      } as Record<string, string>,
    },
  },
  PHOTOGRAPHER_PROPS: {
    USE_PAYPAL: 'a99d34dd-af40-45c5-b342-f594517f83bd',
    USE_VENMO_ID: 'a8612805-f592-449c-a6e5-96f7b780911c',
    USE_VENMO_NO: 'a81ce075-8b13-498f-8906-e2e7d183e38f',
    LOCATION_FROM: {
      CODE: '94291a54-d88d-4ff2-be70-b77b7c46746a',
      LOCATION: 'location',
      ADDRESS: 'address',
    },
  },
  COMPLICATIONS: {
    JOB_TYPE: 'f2b76b67-b451-4839-85dd-af1b0c71facb',
    IMAGE_ORDER_TYPE: '85896497-579f-4777-86bd-c2bd6adf4534',
    VIDEO_ORDER_TYPE: 'e44b3c09-4023-4096-aa5a-0d5ba0ceee7d',
    START_DATE: 'b6280c61-5c61-41ef-aa17-84383e758841',
    END_DATE: 'b41cd441-78db-4638-8f98-14abc9a29417',
    START_DATE_SET: 'b2334139-df17-4263-8a57-e1dcc81484e7',
    START_DATE_NOT_SET: 'b30b0adb-67a1-448f-a9d4-f41380e1c315',
    END_DATE_SET: 'b0588e1f-2f36-43e9-aeef-1d5dbd20446d',
    END_DATE_NOT_SET: 'b19cc9f3-768c-4688-9144-2797a247a2d7',
    DRONE_SHOOT: '5c808741-5be3-468a-8b38-525a8644d2b1',
  } as Record<string, string>,
  COMPLICATION_CHILDREN: {
    IMAGE: '1bcafbcc-f8e8-41c0-b651-08957e382e0a',
    VIDEO: '1bdc2b8c-0886-4b55-ab98-914a7eeba109',
  } as Record<string, string>,
  REQUESTS_STATUSES: {
    SUBMITTED: 'baa912cc-6246-47fa-8c10-1a2c2c010714',
    APPROVED: 'ba2a56dd-31f9-4c8f-9c4d-469b85e32616',
    REJECTED: 'b8327cce-ee33-4143-8c00-08eb9427b38f',
    FAILED: 'b7ccffbc-59ef-46f9-b35d-9309c5074e54',
    DELETED: 'DELETED',
  } as Record<string, string>,
  REQUESTS_STATUSES_BY_GUID: {
    'baa912cc-6246-47fa-8c10-1a2c2c010714': 'Submitted',
    'ba2a56dd-31f9-4c8f-9c4d-469b85e32616': 'Approved',
    'b8327cce-ee33-4143-8c00-08eb9427b38f': 'Rejected',
    'b7ccffbc-59ef-46f9-b35d-9309c5074e54': 'Failed',
    DELETED: 'Deleted',
  } as Record<string, string>,
  FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE: {
    PROCESSING_CONTENT: '3e66de3b-b089-4392-89a3-a3f1d43c0832',
    PROCESSED_CONTENT: '616a2568-967b-4dfb-9265-61ab5ab5201a',
    SUBMITTED_CONTENT: 'be3b5c68-c77c-4a95-9c44-aa5268f28b8a',
    APPROVED_CONTENT: 'bdfd03ad-f644-4e26-a281-c7268221b8c6',
    REJECTED_CONTENT: 'bdccf0b7-3d61-4a64-9a2b-105c0a5193fb',
    DELIVERED_CONTENT: 'bd7aab70-238d-4320-9426-22ba91ccca55',
    FAILED_CONTENT: 'bc8e2b2b-ec0a-4dd6-9048-2eafee0126ce',
    CONVERTING_CONTENT: 'b63afbc2-8dd6-4a37-ac08-8902a5b88fc6',
    DUPLICATED_CONTENT: '90b4c4eb-2198-48be-bf01-1b51535a8148',
    OUT_OF_FOCUS_CONTENT: '21efd565-d91b-48dd-b45d-410abf8e9622',
  } as Record<string, string>,
  FULFILMENT_CONTENT_STATUSES_BY_GUID: {
    '3e66de3b-b089-4392-89a3-a3f1d43c0832': 'Processing',
    '616a2568-967b-4dfb-9265-61ab5ab5201a': 'Processed',
    'be3b5c68-c77c-4a95-9c44-aa5268f28b8a': 'Submitted',
    'bdfd03ad-f644-4e26-a281-c7268221b8c6': 'Approved',
    'bdccf0b7-3d61-4a64-9a2b-105c0a5193fb': 'Rejected',
    'bd7aab70-238d-4320-9426-22ba91ccca55': 'Delivered',
    'bc8e2b2b-ec0a-4dd6-9048-2eafee0126ce': 'Failed',
    'b63afbc2-8dd6-4a37-ac08-8902a5b88fc6': 'Converting Content',
    '90b4c4eb-2198-48be-bf01-1b51535a8148': 'Duplicate',
    '21efd565-d91b-48dd-b45d-410abf8e9622': 'Unfocused',
  },
  WITH_DELETED: 'withDeleted',
  ONLY_DELETED: 'onlyDeleted',
  CARDS: {
    CAPTURE_PENDING: '7827d6fe-6c67-40e7-82f5-c2d00cc8ae6f',
  },
  INVOICE_INFO_GUID: 'a9c40b5e-7283-4e93-8537-9d1bc10227af',
  CARD_INFO_GUID: 'ee2018cb-748b-4d60-b93d-8080315f6ec0',
  DEFAULT_PAY_INSTRUMENT: '7134a53c-68cd-4061-b2c0-a7e6b06abd6c',
  DEFAULT_PAY_METHOD: 'af26a292-cf2e-4d22-b923-e409496dfdeb',
  INDUSTRY: {
    GENERAL: 'aea7b246-dc6c-4d65-85c6-8c412f75b9d0',
  },
  PURCHASE_TARGETS: {
    PAYPAL: '9a9e31c8-aee4-491b-890b-7b0721199398',
    VENMO: 'c1377125-dee4-437b-aa7b-f85f3f291222',
  },
  PAYMENT_OPERATIONS_STATUSES: {
    AUTHORIZE_PENDING: 'f2c012b7-c52c-418d-b5f7-42c6192b7865',
    CANCELLED: '4cda9776-ec46-4437-bf32-cd4417023c3a',
    CAPTURE_PENDING: '7827d6fe-6c67-40e7-82f5-c2d00cc8ae6f',
    CREATED: 'd10e19d8-bfa4-49f9-acce-52592941313f',
    DECLINED_HOLD: '91315798-d965-4c20-907a-cb846aa87072',
    DECLINED_SETTLE: '60606a78-35f2-4042-862f-c13e6905309f',
    EXPIRED: 'd2483718-b5ca-4b72-93ad-5025fa86f323',
    FAILED: '6075f770-3fc8-4d44-9143-6dad121db39e',
    GATEWAY_REJECTED: 'ad5e6c3a-1570-49ac-ae33-890d27f59d70',
    HOLD: '60caff4c-22e1-4142-9543-8f524c80e01c',
    NOT_VERIFIED: 'fc0261ac-c3fc-4c09-9d9e-c0b72d6d90ef',
    REFUND_FAILED: '12d480bb-8416-423c-9d1a-e2a421d1bafe',
    REFUND_PENDING: '4f6501c4-f648-4da9-9c1a-975cafba352a',
    REFUNDED: 'a41e94a1-9b48-47c6-9689-0846b38b7603',
    SETTLED: '1edfb9a3-8ae3-47d4-be66-1de42dcf01a3',
    VERIFIED: 'ff706cba-c641-414f-baa3-b7328ca7793e',
    VERIFYING: '31469bc1-c77a-4043-b2fd-d3a8fc5f78dd',
  },
  PAYMENT_OPERATIONS_STATUSES_BY_GUID: {
    'f2c012b7-c52c-418d-b5f7-42c6192b7865': 'Authorize pending',
    '4cda9776-ec46-4437-bf32-cd4417023c3a': 'Cancelled',
    '7827d6fe-6c67-40e7-82f5-c2d00cc8ae6f': 'Capture pending',
    'd10e19d8-bfa4-49f9-acce-52592941313f': 'Created',
    '91315798-d965-4c20-907a-cb846aa87072': 'Declined hold',
    '60606a78-35f2-4042-862f-c13e6905309f': 'Declined settle',
    'd2483718-b5ca-4b72-93ad-5025fa86f323': 'Expired',
    '6075f770-3fc8-4d44-9143-6dad121db39e': 'Failed',
    'ad5e6c3a-1570-49ac-ae33-890d27f59d70': 'Gateway rejected',
    '60caff4c-22e1-4142-9543-8f524c80e01c': 'Hold',
    'fc0261ac-c3fc-4c09-9d9e-c0b72d6d90ef': 'Not verified',
    '12d480bb-8416-423c-9d1a-e2a421d1bafe': 'Refund failed',
    '4f6501c4-f648-4da9-9c1a-975cafba352a': 'Refund pending',
    'a41e94a1-9b48-47c6-9689-0846b38b7603': 'Refunded',
    '1edfb9a3-8ae3-47d4-be66-1de42dcf01a3': 'Settled',
    'ff706cba-c641-414f-baa3-b7328ca7793e': 'Verified',
    '31469bc1-c77a-4043-b2fd-d3a8fc5f78dd': 'Verifying',
  },
  SYSTEM_PREFERENCES: {
    PAYOUT_PROVIDER: '7d20b429-c631-4592-a0de-976ff2957643',
  },
};
