import ProTable from '@ant-design/pro-table';
import { FunctionComponent } from 'react';
import { orderComplicationsColumnsNested } from './columnsNested';
import { queryNames } from 'api/queryNames';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { columns } from './columns';

type OrderComplicationsNestedTableT = {
  orderId: string | undefined;
};

export const OrderComplicationsNestedTable: FunctionComponent<
  OrderComplicationsNestedTableT
> = ({ orderId }) => {
  const token = useSelector((state: any) => state.auth.token);

  const {
    data: dataOrderComplications,
    isFetching: isLoadingOrderComplications,
  } = useQuery({
    queryKey: [queryNames.ORDERS, orderId, 'complications'],
    meta: {
      token,
      getAllData: true,
      columnParams: columns,
      additionalSearchParams: `?id=${orderId}`,
    },
    queryFn: useGetData,
    enabled: !!orderId,
  });

  if (!orderId) return <></>;
  return (
    <div>
      <ProTable
        columns={orderComplicationsColumnsNested(
          orderId,
          dataOrderComplications?.data?.['0']?.currency?.code
        )}
        dataSource={dataOrderComplications?.data?.['0']?.prices}
        options={false}
        pagination={false}
        headerTitle={null}
        bordered={false}
        toolbar={undefined}
        search={false}
        loading={isLoadingOrderComplications}
        size="small"
      />
    </div>
  );
};
