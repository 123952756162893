import { signOutUser } from 'shared/utils/api/signOutUser';
import { setSessionLifeTime } from 'shared/utils/setSessionLifeTime';
import { APP_CONSTANS } from '../shared/constants';
import { message } from 'antd';

const useGetData = async (props: any) => {
  // const updatedMeta = props?.meta?.updatedMeta;
  // updatedMeta && delete props.meta.updatedMeta;
  const meta = { ...props.meta, ...props.meta.meta };
  meta && delete meta.meta;
  // const meta = updatedMeta ? {...props?.meta, ...updatedMeta} : props?.meta ? props.meta : null;
  const offset = props?.pageParam || 0;
  let LIMIT = APP_CONSTANS.GLOBAL_FETCH_LIMIT;
  if (meta?.hasOwnProperty('limit') && meta?.limit) {
    LIMIT = meta.limit;
  }
  const token = meta?.token;
  const columnParams = meta?.columnParams;
  const setNewColumnsParams = meta?.setNewColumnsParams;
  let sorter = meta?.sorter || '';
  let order = '';
  if (!Array.isArray(sorter)) {
    sorter = [sorter];
  }
  sorter &&
    sorter.length > 0 &&
    sorter.forEach((sortObj: any) => {
      if (sortObj && sortObj.field && sortObj.order) {
        order += sortObj?.column?.isJson
          ? `&order=${sortObj.column.alias}%3A%3A${sortObj.column.name}%3A%3A${
              sortObj.column.type
            }%3A%3A${
              sortObj.order === 'ascend'
                ? 'asc'
                : sortObj.order === 'descend'
                ? 'desc'
                : ''
            }`
          : `&order=${sortObj.field}%20${
              sortObj.order === 'ascend'
                ? 'asc'
                : sortObj.order === 'descend'
                ? 'desc'
                : ''
            }`;
      }
    });
  const filters = meta?.filters || '';
  const searchArray: any[] = [];
  const likesArray: any[] = [];
  const codesArray: any[] = [];
  const statusesArray: any[] = [];
  const rangeArray: any[] = [];
  let includedDeleted = props.queryKey.includes(APP_CONSTANS.WITH_DELETED)
    ? true
    : meta?.includedDeleted && meta?.includedDeleted.hasOwnProperty('current')
    ? meta.includedDeleted.current
    : meta?.includedDeleted
    ? meta.includedDeleted
    : '';
  let onlyDeleted = props.queryKey.includes(APP_CONSTANS.ONLY_DELETED)
    ? true
    : meta?.onlyDeleted && meta?.onlyDeleted.hasOwnProperty('current')
    ? meta.onlyDeleted.current
    : meta?.onlyDeleted
    ? meta.onlyDeleted
    : '';
  let types =
    meta?.types && meta?.types.hasOwnProperty('current')
      ? meta.types.current
      : meta?.types
      ? meta.types
      : '';
  const getAllData = meta?.hasOwnProperty('getAllData') ? true : false;

  if (filters) {
    const activeFilters = Object.keys(filters)
      .filter((k) => filters[k]['key'] !== null)
      .reduce((a, k) => ({ ...a, [k]: filters[k] }), {});
    for (let filterObj in activeFilters) {
      if (filterObj) {
        let { key } = filters[filterObj];
        const { isJson } = filters[filterObj];
        if (key && key.join(' ').includes('+')) {
          key = key.map((keyItem: any) => {
            if (keyItem.includes('+')) {
              let keyItemNew = keyItem.replaceAll('+', '%2b');
              return keyItemNew;
            }
            return keyItem;
          });
        }
        // if (filters[filterObj] && filterObj === 'code') {
        //     codesArray.push(`&codes=${filters[filterObj][0]}`);
        // } else
        if (key && filterObj === 'status') {
          statusesArray.push(`&statuses=${key[0]}`);
        } else if (key && filterObj.includes('__search') && !isJson) {
          searchArray.push(
            `&value_like=${filterObj.replace('__search', '')}%2C${key.join(
              '%2C'
            )}`
          );
        } else if (key && filterObj.includes('__search') && isJson) {
          searchArray.push(
            `&json_like=${filterObj.replace('__search', '')}%3A%3A${key.join(
              ''
            )}`
          );
        } else if (key && filterObj.includes('__range')) {
          rangeArray.push(
            `&time_range=${filterObj.replace('__range', '')}%2C${key.join(
              '%2C'
            )}`
          );
        } else if (key && filterObj.includes('__code')) {
          codesArray.push(
            `&value_code=${filterObj.replace('__code', '')}%2C${key.join(
              '%2C'
            )}`
          );
        } else if (key && key.length > 1 && !isJson) {
          likesArray.push(`&value_in=${filterObj}%2C${key.join('%2C')}`);
        } else if (key && key.length === 1 && !isJson) {
          likesArray.push(`&value_equal=${filterObj}%2C${key[0]}`);
        } else if (key && key.length > 1 && isJson) {
          likesArray.push(`&json_in=${filterObj}${key.join('%3A%3A')}`);
        } else if (filterObj.startsWith('prices')) {
          likesArray.push(`&json_like_array=${filterObj}${key}`);
        } else if (key && key.length === 1 && isJson) {
          likesArray.push(`&json_equal=${filterObj}${key}`);
        }
      }
    }
  }

  const additionalSearchParams = meta?.additionalSearchParams || '';
  const signForRelations = additionalSearchParams ? '&' : '?';
  const getDataForTable = getAllData
    ? order.length > 0
      ? `${signForRelations}${order.slice(1)}`
      : ''
    : `${signForRelations}limit=${LIMIT}${order}&offset=${offset}`;
  const customSearchParams = meta?.searchParams || '';

  const searchParams = (
    `${additionalSearchParams ? additionalSearchParams : ''}` +
    `${getDataForTable}` +
    `${likesArray.length > 0 ? likesArray.join('') : ''}` +
    `${codesArray.length > 0 ? codesArray.join('') : ''}` +
    `${searchArray.length > 0 ? searchArray.join('') : ''}` +
    `${statusesArray.length > 0 ? statusesArray.join('') : ''}` +
    `${rangeArray.length > 0 ? rangeArray.join('') : ''}` +
    `${includedDeleted ? '&with_deleted=' + includedDeleted : ''}` +
    `${onlyDeleted ? '&only_deleted=' + onlyDeleted : ''}` +
    `${types ? `&types=${types}` : ''}` +
    `${columnParams ? `&columns=${columnParams.join('&columns=')}` : ''}` +
    `${customSearchParams}`
  ).replace(/#/g, '%23');

  const resolveUrl = () => {
    if (meta?.webFilesApi) return APP_CONSTANS.BASE_FILES_URL;
    if (meta?.paymentsApi) return APP_CONSTANS.BASE_PAYMENTS_URL;
    return APP_CONSTANS.BASE_API_URL_V2;
  };

  const completedUrl = `${resolveUrl()}/${
    props.queryKey[0].includes('all_')
      ? props.queryKey[0].split('_')[1]
      : props.queryKey[0]
  }${
    searchParams.charAt(0) === '&' ? `?${searchParams.slice(1)}` : searchParams
  }`;

  if (meta?.saveUrl) {
    sessionStorage.setItem('lastGet', completedUrl);
  }

  try {
    const response = await fetch(completedUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error(response.statusText);

    const data = await response.json();
    setSessionLifeTime();
    const dataWithOffset = {
      ...data,
      nextCursor: offset + LIMIT,
    };
    if (setNewColumnsParams) {
      setNewColumnsParams(false);
    }
    return offset + data.data?.length < data.count ? dataWithOffset : data;
  } catch (error: any) {
    error instanceof TypeError && message.error(error.message);
    if (error?.message === 'Unauthorized') {
      console.log('unauthorized -> sign out');
      signOutUser();
    }
    throw new Error(error);
  }
};

export default useGetData;
