import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const orderComplicationsColumnsNested = (
  id: string,
  currencyCode: string
) => {
  const bold = (value: any) => (
    <span className={clsx(styles.name, 'nestedCell')}>{value}</span>
  );
  const plain = (value: any) => <span className="nestedCell">{value}</span>;

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name' + id,
      render: (value: any) => bold(value),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value' + id,
      render: (value: any) => plain(value),
    },
    {
      title: 'Input value',
      dataIndex: 'input_value',
      key: 'input_value' + id,
      render: (value: any) => plain(value),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount' + id,
      render: (value: any) => {
        return plain(renderMoneyWithCurrency(value, currencyCode));
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price' + id,
      render: (value: any) => {
        return plain(renderMoneyWithCurrency(value, currencyCode));
      },
    },
  ];
};
