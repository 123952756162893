import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  message,
} from 'antd';
import styles from './styles.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import useGetData from 'api/useGetData';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import globalStyles from 'shared/styles/styles.module.scss';
import { queryNames } from 'api/queryNames';
import { isGuid } from 'shared/utils/isGuid';
import {
  InvoiceDrawerPropsT,
  OrdersNotInvoicedDataT,
  OrdersNotInvoicedListT,
} from './types';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { Loader } from 'components/ui_components/Loader';
import { APP_CONSTANS } from 'shared/constants';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CreditCardTwoTone, FileTextTwoTone } from '@ant-design/icons';
import { columnsAllNotInvoiced, columnsNotInvoicedByAccount } from './columns';
import { PaymentTypeSelect } from '../PaymentTypeSelect';
import { getCurrensySymbol } from 'shared/utils/getCurrensySymbol';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';

export const CreateEditInvoiceDrawer: React.FC<InvoiceDrawerPropsT> = ({
  show,
  setShow,
  setRefetchData,
  accountIdForEdit = '',
  invoiceObject,
  setLinesKey,
}) => {
  const [invoiceForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const [value, setValue] = useState<string>('');
  const [haveChanges, setHaveChanges] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>(accountIdForEdit);
  const [currentCurrencyId, setCurrentCurrencyId] = useState<string>(
    invoiceObject?.invoiceObjectCurrencyId || APP_CONSTANS.CURRENCY_USA_USD_GUID
  );
  const [selectedOrders, setSelectedOrders] = useState<
    OrdersNotInvoicedListT[]
  >([]);

  const getAllData = true;

  const restrictEditRules = !(
    !!accountIdForEdit &&
    invoiceObject &&
    ![
      APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Draft,
      APP_CONSTANS.INVOICE_STATUSES_BY_NAME.NotPaid,
    ].includes(invoiceObject?.invoiceObjectStateId)
  );

  const { isInitialLoading: currencyDataLoading, data: currencyData } =
    useQuery({
      queryKey: [queryNames.Currencies],
      meta: {
        token,
        getAllData,
        additionalSearchParams: `?order=name%20desc&value_equal=curr.is_supported%2Ctrue&columns=name&columns=code&columns=is_supported&columns=id`,
      },
      queryFn: useGetData,
      retry: 2,
    });

  const { isInitialLoading: ordersListLoading, data: ordersList } =
    useQuery<OrdersNotInvoicedDataT>({
      queryKey: [queryNames.ORDERS, accountId, 'notInvoiced'],
      meta: {
        token,
        getAllData,
        additionalSearchParams: `${
          columnsNotInvoicedByAccount
            ? `&columns=${columnsNotInvoicedByAccount.join('&columns=')}`
            : ''
        }&account_ids=${accountId}&without_invoice=true&value_equal=state.id%2C${
          APP_CONSTANS.ORDER_STATUSES_GUID_BY_CODE.COMPLETED_ORDER
        }`,
      },
      queryFn: useGetData,
      enabled: !!accountId,
      retry: 2,
      select: (data) => {
        const currId = invoiceObject?.invoiceObjectCurrencyId;
        data.data = data.data.sort((a, b) => a.completed_at - b.completed_at);
        if (currId && Array.isArray(data.data)) {
          const filteredOrders = data.data.filter(
            (o) =>
              o.currency.id === currId &&
              o.payment_type === invoiceObject.invoiceObjectPaymentType
          );
          return { ...data, data: filteredOrders };
        }
        return data;
      },
    });

  const {
    isInitialLoading: allAccountsWithNotInvoicedOrdersLoading,
    data: allAccountsWithNotInvoicedOrders,
  } = useQuery({
    queryKey: [queryNames.ORDERS, 'allNotInvoiced'],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `${
        columnsAllNotInvoiced
          ? `&columns=${columnsAllNotInvoiced.join('&columns=')}`
          : ''
      }&without_invoice=true&value_equal=state.id%2C${
        APP_CONSTANS.ORDER_STATUSES_GUID_BY_CODE.COMPLETED_ORDER
      }`,
    },
    queryFn: useGetData,
    retry: 2,
    select: (data) => {
      if (data?.data) {
        const counts: { [key: string]: { name: string; count: number } } =
          data.data.reduce((acc: any, cur: any) => {
            const { id } = cur.account;
            const { name } = cur.account;
            if (!acc.hasOwnProperty(id)) {
              acc[id] = { name, count: 0 };
            }
            acc[id].count += 1;
            return acc;
          }, {});
        return counts;
      }
      return {};
    },
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Server Error ${data.error?.message}`);
        return;
      } else {
        message.success(
          accountIdForEdit
            ? 'Invoice was successfully updated'
            : `${data?.data?.code || ''} Invoice was successfully created`
        );
        accountIdForEdit ? setRefetchData() : setRefetchData(true);
        setLinesKey && setLinesKey(Date.now().toString());
        invoiceForm.resetFields();
        setHaveChanges(false);
        handleClear();
        setShow(false);
      }
    },
  });

  useEffect(() => {
    if (accountIdForEdit) {
      setSelectedOrders([]);
      return;
    }
    if (
      currentCurrencyId &&
      ordersList &&
      ordersList?.data &&
      ordersList.data.length
    ) {
      const idsArr = ordersList.data.filter(
        (order) => order.currency.id === currentCurrencyId
      );
      setSelectedOrders(idsArr);
    }
  }, [currentCurrencyId, ordersList, accountIdForEdit]);

  const handleSubmitData = (allFields: any) => {
    if (!accountId) {
      message.error('Account ID is undefined');
      return;
    }
    if (!currentCurrencyId) {
      message.error('Currency is not selected');
      return;
    }
    let dataToBack: object = {};
    if (!accountIdForEdit && !invoiceObject) {
      dataToBack = {
        account_id: allFields.accountId,
        currency_id: currentCurrencyId,
        ...(allFields.discount && { discount: allFields.discount }),
        ...(allFields.memo && { memo: allFields.memo }),
        ...(allFields.payment_instrument && {
          payment_instrument: allFields.payment_instrument,
        }),
        order_ids: selectedOrders.map((o) => o.id),
        payment_type:
          (allFields?.payment_type &&
            allFields.payment_type[0].toUpperCase() +
              allFields.payment_type.slice(1)) ||
          '',
      };
    } else {
      dataToBack = {
        invoice_id: invoiceObject?.invoiceObjectId,
        ...(allFields.discount && { discount: allFields.discount }),
        ...(allFields.memo && { memo: allFields.memo }),
        ...(allFields.external_id && { external_id: allFields.external_id }),
        add_order_ids: selectedOrders.map((o) => o.id),
      };
    }

    mutate({
      data: dataToBack,
      token,
      otherProps:
        !accountIdForEdit && !invoiceObject
          ? queryNames.INVOICE_CREATE
          : queryNames.INVOICE_UPDATE,
    });
  };

  const handleAccountChange = (id: string | undefined) => {
    if (id && isGuid.test(id)) {
      setAccountId(id);
    }
  };

  const onClose = () => {
    if (haveChanges) {
      // eslint-disable-next-line no-alert
      const conf = window.confirm(
        `You haven't finished creation. Do you want to quit? Entered data won't be saved`
      );
      if (conf) {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  const optionsListAccounts = () => {
    if (
      !allAccountsWithNotInvoicedOrders ||
      !Object.keys(allAccountsWithNotInvoicedOrders).length
    ) {
      return [
        {
          value: '',
          label: 'No maches found',
        },
      ];
    }

    if (allAccountsWithNotInvoicedOrdersLoading) {
      return [
        {
          value: '',
          label: 'Loading...',
        },
      ];
    }

    return Object.entries(allAccountsWithNotInvoicedOrders).map(
      ([id, { count, name }]) => ({
        value: id,
        label: `${name} (${count})`,
      })
    );
  };

  const checkboxLable = (checkboxObject: OrdersNotInvoicedListT) => {
    const { title, code, total, currency, completed_at } = checkboxObject;
    const totalValue = renderMoneyWithCurrency(total, currency.code);

    let payIcon: string | ReactElement = checkboxObject.payment_type;

    if (checkboxObject.payment_type === 'Card') {
      payIcon = <CreditCardTwoTone twoToneColor="#38a306" />;
    }
    if (checkboxObject.payment_type === 'Invoice') {
      payIcon = <FileTextTwoTone />;
    }
    return (
      <div className={styles.orderLine}>
        <p className={styles.orderLine__code}>{code}</p>
        <p>{convertUNIXToLocale(completed_at, null)}</p>
        <p className={styles.orderLine__title} title={title}>
          {title}
        </p>
        <p className={styles.orderLine__total}>{totalValue}</p>
        <Tooltip
          className={styles.orderLine__icon}
          title={`Payment type - ${checkboxObject.payment_type}`}
        >
          <span>{payIcon}</span>
        </Tooltip>
      </div>
    );
  };

  const handleCheckOrder = (
    e: CheckboxChangeEvent,
    checkedOrder: OrdersNotInvoicedListT
  ) => {
    if (e.target.checked) {
      setHaveChanges(true);
      setSelectedOrders([...selectedOrders, checkedOrder]);
    } else {
      setSelectedOrders(selectedOrders.filter((o) => o.id !== checkedOrder.id));
    }
  };

  const handleCurrencyChange = (currencyId: string) => {
    setSelectedOrders([]);
    setCurrentCurrencyId(currencyId);
  };

  const handleClear = () => {
    setValue('');
    setAccountId('');
    setSelectedOrders([]);
  };

  return (
    <Drawer
      width={window.innerWidth < 800 ? '100%' : '800px'}
      title={accountIdForEdit ? 'Edit Invoice' : 'New Invoice'}
      placement="right"
      onClose={() => {
        handleClear();
        onClose();
      }}
      open={show}
      extra={
        <Button
          className={clsx(globalStyles.btn, globalStyles.primary__btn)}
          htmlType="submit"
          type="primary"
          onClick={invoiceForm.submit}
          disabled={
            accountIdForEdit && invoiceObject ? false : !selectedOrders.length
          }
        >
          {accountIdForEdit ? 'Update' : 'Create'}
        </Button>
      }
    >
      <Form
        className={styles.form}
        layout="vertical"
        form={invoiceForm}
        onFinish={handleSubmitData}
        autoComplete="off"
        onFieldsChange={() => {
          setHaveChanges(true);
        }}
      >
        <div>
          <Form.Item
            name="accountId"
            label={
              <span className={styles.form__item_extra}>Select account</span>
            }
            className={styles.form__item}
            hidden={!!accountIdForEdit}
          >
            <Select
              loading={allAccountsWithNotInvoicedOrdersLoading}
              showSearch
              allowClear
              placeholder="Find account by name"
              value={value}
              defaultActiveFirstOption={false}
              filterOption={(
                input: string,
                option?: { label: string; value: string }
              ) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onClear={handleClear}
              onChange={handleAccountChange}
              options={optionsListAccounts()}
              size="small"
            />
          </Form.Item>
        </div>
        <Form.Item
          name="currency"
          label={
            <span className={styles.form__item_extra}>
              Supported currencies
            </span>
          }
          className={styles.form__item}
          initialValue={currentCurrencyId}
          hidden={!!accountIdForEdit}
        >
          <Select
            onSelect={handleCurrencyChange}
            loading={currencyDataLoading}
            size="small"
            options={
              currencyData?.count
                ? currencyData?.data?.map(
                    (cur: { id: string; name: string }) => ({
                      value: cur.id,
                      label: cur.name,
                    })
                  )
                : []
            }
          />
        </Form.Item>
        <PaymentTypeSelect
          isHidden={!!accountIdForEdit}
          isDisabled={!accountId}
          setSelectedOrders={setSelectedOrders}
          accountId={accountId}
          formInstance={invoiceForm}
        />
        {restrictEditRules && (
          <Form.Item
            name="discount"
            label={<span className={styles.form__item_extra}>Discount</span>}
            className={styles.form__item}
            initialValue={invoiceObject?.invoiceObjectDiscount ?? 0}
          >
            <InputNumber
              key={currentCurrencyId}
              style={{ width: '100%' }}
              size="small"
              min={0}
              prefix={getCurrensySymbol(
                currencyData?.data?.find(
                  (currObj: { id: string }) => currObj?.id === currentCurrencyId
                )?.code || 'USD'
              )}
              formatter={(valueFormatter: any) =>
                (
                  Math.round((valueFormatter / 1000 + Number.EPSILON) * 100) /
                  100
                ).toString()
              }
              parser={(valueParser: any) =>
                Math.round(Number(valueParser) * 1000)
              }
              precision={2}
              step={1000}
            />
          </Form.Item>
        )}
        <Form.Item
          name="external_id"
          label={<span className={styles.form__item_extra}>External ID</span>}
          className={styles.form__item}
          initialValue={invoiceObject?.invoiceObjectExternalId || ''}
          hidden={!accountIdForEdit}
        >
          <Input.TextArea size="small" />
        </Form.Item>
        <Form.Item
          name="memo"
          label={<span className={styles.form__item_extra}>Memo</span>}
          className={styles.form__item}
          initialValue={invoiceObject?.invoiceObjectMemo || ''}
        >
          <Input.TextArea size="small" />
        </Form.Item>

        {restrictEditRules && (
          <div className={styles.form__block}>
            {ordersListLoading ? (
              <Loader />
            ) : !ordersList?.count ? (
              <p>
                {accountId
                  ? 'Not found completed Orders in selected Accounts'
                  : 'Please select Account'}
              </p>
            ) : (
              <>
                <p className={styles.form__title}>
                  <span>
                    Completed Orders in Account
                    <span style={{ fontWeight: 'normal' }}>
                      {' '}
                      (sorted by Completed date)
                    </span>
                    {invoiceObject &&
                      ` (${invoiceObject?.invoiceObjectAccountName})`}
                  </span>
                </p>
                <Divider className={styles.divider} />
                {Array.isArray(ordersList?.data) &&
                  ordersList.data.map((order) => (
                    <Checkbox
                      key={order.id}
                      rootClassName={styles.orderLineLabel}
                      className={styles.orderLineWrapper}
                      onChange={(e) => handleCheckOrder(e, order)}
                      checked={selectedOrders.some(
                        (ord) => ord.id === order.id
                      )}
                      disabled={
                        // order.payment_type.toLowerCase() !==
                        //   currentPaymentType.toLowerCase() ||
                        order.currency.id !== currentCurrencyId
                      }
                    >
                      {checkboxLable(order)}
                    </Checkbox>
                  ))}
              </>
            )}
          </div>
        )}
      </Form>
    </Drawer>
  );
};
