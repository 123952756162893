import {
  CameraOutlined,
  MinusOutlined,
  PlusOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import useGetData from 'api/useGetData';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import {
  renderLink,
  renderTitleLink,
} from 'shared/utils/DataInTables/renderLinks';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import general_styles from '../../../shared/styles/styles.module.scss';
import Table from '../Table';
import styles from './styles.module.scss';
import { OrdersTableT } from './types';
import { queryNames } from 'api/queryNames';
import { columns } from './columns';
import { OrdersPageTable } from 'components/pages/main/orders_section/Orders/types';
import { OrderComplicationsNestedTable } from './components/OrderComplicationsNestedTable';

export const OrdersTable: FunctionComponent<OrdersTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'ordersTable',
  type = OrdersPageTable.ALL,
  showComplications = false,
  showSearchByComplications = false,
  ...rest
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [complicationChildren, setComplicationChildren] = useState<any>(null);

  const { data, isFetching } = useQuery({
    queryKey: [queryNames.COMPLICATION_CHILDREN],
    meta: { token, getAllData: true },
    queryFn: useGetData,
  });

  useEffect(() => {
    if (data && data.hasOwnProperty('data')) {
      setComplicationChildren(data.data);
    }
  }, [data]);

  const expandedRow = (record: any) => {
    const id = record?.id;
    return <OrderComplicationsNestedTable orderId={id} />;
  };

  const renderType = (value: string) => {
    let complicationName = '';
    if (value) {
      let complication = complicationChildren.find(
        (compl: any) => compl.id === value
      );
      complicationName = complication ? complication.name : value;
    }
    return (
      <span className={styles.orderType}>
        {value === APP_CONSTANS.COMPLICATION_CHILDREN.IMAGE ? (
          <CameraOutlined />
        ) : value === APP_CONSTANS.COMPLICATION_CHILDREN.VIDEO ? (
          <VideoCameraOutlined />
        ) : (
          ''
        )}
        <span>{complicationName}</span>
      </span>
    );
  };

  const renderOrderStatus = (value: string, record: any) => {
    const statusName = record?.['state.name'];
    const statusId = record?.['state.id'];

    return (
      <span
        className={general_styles.orderStatus}
        data-order-status={value || statusId}
      >
        {statusName}
      </span>
    );
  };

  const renderDataColumn = (value: string, record: any) => {
    const currency = record?.['currency.code'];
    const accountId = record?.['account.id'];
    const userId = record?.['user.id'];
    const stateId = record?.['state.id'];
    const invoiceId = record?.['invoice.id'];

    return [
      {
        name: 'code',
        action: renderLink(value, `/orders/${type}/${record && record.id}`),
      },
      {
        name: 'title',
        action: renderTitleLink(
          value,
          `/orders/${type}/${record && record.id}`
        ),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'invoice.code',
        action:
          value && value?.length > 1 ? (
            renderLink(value, `/invoices/all/${invoiceId}`)
          ) : (
            <></>
          ),
      },
      {
        name: 'user.first_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'user.last_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'content_type',
        action: renderType(value),
      },
      {
        name: 'state_id',
        action: renderOrderStatus(stateId, record),
      },
      {
        name: 'total',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'price',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'discount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'tax_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payout',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'overhead',
        action: renderMoneyWithCurrency(value, currency),
      },
    ];
  };

  return complicationChildren && !isFetching ? (
    <Table
      tableIndicatorClassName={tableIndicatorClassName}
      searchingByAllTable={false}
      rowSelection={null}
      rowSelectionType={null}
      queryKey={queryKey}
      meta={meta}
      toolbar={toolbar}
      renderDataColumn={renderDataColumn}
      headerName={headerName}
      actionsColumn={actionsColumn}
      defaultEnabledSettings={defaultEnabledSettings}
      parametrForSavingSettings={tableName}
      hideInSetting={hideInSetting}
      quantityTitleText="orders"
      expandedRowRender={
        showComplications ? (record: any) => expandedRow(record) : null
      }
      defaultSorter={{
        field: 'ord.created_at',
        order: 'descend',
      }}
      columnsForRequest={columns}
      expandIcon={
        showComplications
          ? ({ expanded, onExpand, record }: any) =>
              expanded ? (
                <MinusOutlined
                  className={styles.expandIcon}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <PlusOutlined
                  className={styles.expandIcon}
                  onClick={(e) => onExpand(record, e)}
                />
              )
          : null
      }
      showSearchByComplications={showSearchByComplications}
      {...rest}
    />
  ) : (
    <></>
  );
};
