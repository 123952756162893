import { Form, Input, InputNumber, Radio, Select } from 'antd';
import clsx from 'clsx';
import { userTitles } from './constants';
import styles from './styles.module.scss';
import { DataAccountPreferencesI } from './types';
const { Option } = Select;

export const preferenceGenerator = (
  pref: DataAccountPreferencesI,
  { type, numberOfCompletedJobs }: { type: any; numberOfCompletedJobs?: number }
) => {
  const preferences = [];

  if (
    pref.preference.pref_mode === 'select' &&
    pref.preference?.options?.length
  ) {
    preferences.push(
      <Form.Item
        key={pref.preference_id}
        name={`pref#${pref.preference_id}`}
        className={styles.prefs__item}
        initialValue={pref.value}
      >
        <Select
          status={`${
            pref.preference_id === 'a4917f4f-a949-4038-b9cb-e946ebe660b8' &&
            !pref.value
              ? 'error'
              : ''
          }`}
          placeholder={`${
            pref.preference_id === 'a4917f4f-a949-4038-b9cb-e946ebe660b8' &&
            !pref.value
              ? 'Default account is not have this user.'
              : ''
          }`}
          value={pref.value}
          popupClassName={styles.prefs__dropdown}
          className={styles.prefs__select}
        >
          {Array.isArray(pref.preference?.options) &&
            pref.preference?.options.map((option: any) => (
              <Option key={option.id} value={option.value}>
                {pref.preference_id === 'a3cc8111-d107-49a8-a5e8-4bce9b1cf341'
                  ? option.value
                  : option.title}
              </Option>
            ))}
        </Select>
      </Form.Item>
    );
  }

  if (
    pref.preference.pref_mode === 'input' &&
    pref.preference.pref_type === 'number'
  ) {
    preferences.push(
      <Form.Item
        key={pref.preference_id}
        name={`pref#${pref.preference_id}`}
        className={styles.prefs__item}
        initialValue={pref.value ? pref.value : 0}
      >
        <InputNumber
          value={pref.value ? pref.value : 0}
          min={0}
          max={10000}
          className={styles.prefs__input}
          step={1}
        />
      </Form.Item>
    );
  }

  if (
    pref.preference.pref_mode === 'input' &&
    pref.preference.pref_type === 'date'
  ) {
    const convertedDate = pref?.value?.toString().includes('T')
      ? pref?.value?.toString().split('T')[0]
      : pref.value;
    preferences.push(
      <Form.Item
        key={pref.preference_id}
        name={`pref#${pref.preference_id}`}
        className={styles.prefs__item}
        initialValue={convertedDate}
      >
        <Input type="date" size="small" className={clsx(styles.prefs__input)} />
      </Form.Item>
    );
  }

  if (
    pref.preference.pref_mode === 'input' &&
    pref.preference.pref_type === 'text'
  ) {
    preferences.push(
      <Form.Item
        key={pref.preference_id}
        name={`pref#${pref.preference_id}`}
        className={styles.prefs__item}
        initialValue={pref.value}
      >
        <Input
          className={clsx(styles.prefs__input)}
          placeholder={pref.preference.value ? '' : 'Not provided'}
        />
      </Form.Item>
    );
  }

  if (pref.preference.pref_mode === 'radio' && pref.value) {
    preferences.push(
      <Form.Item
        key={pref.preference_id}
        name={`pref#${pref.preference_id}`}
        className={styles.prefs__item}
        initialValue={pref.value}
      >
        {Array.isArray(pref.preference?.options) ? (
          <Radio.Group value={pref.value}>
            {pref.preference?.options.map((option: any) => (
              <Radio key={option.id} value={option.value}>
                {option.title}
              </Radio>
            ))}
          </Radio.Group>
        ) : (
          <Input
            className={clsx(styles.prefs__input)}
            disabled={true}
            value={pref.value}
          />
        )}
      </Form.Item>
    );
  }

  if (
    type !== 'editor' &&
    pref.preference_id === 'a032c8a0-d190-4db5-8f5b-6ba8a3761e3e'
  ) {
    preferences.push(
      <p key={pref.preference_id + 'label'} className={styles.label}>
        Number of completed jobs:{' '}
        <span className={styles.textValue}>{numberOfCompletedJobs}</span>
      </p>
    );
  }

  if (!preferences.length) {
    return null;
  }

  return (
    <div className={styles.prefs__block} key={pref.preference_id + 'wrapper'}>
      {userTitles?.[pref.preference_id] && (
        <p className={styles.prefs__title}>{userTitles[pref.preference_id]}</p>
      )}
      <p className={styles.label}>{pref.preference.title}</p>
      {preferences}
    </div>
  );
};
