import { Divider, message, Select, Tag, Tooltip } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ContentTagsT, TagRender } from './types';
import { useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { MetadataT } from 'components/pages/main/orders_section/FulfilmentPage/types';

export const ContentTags: FC<ContentTagsT> = ({
  contentId,
  topDivider = false,
  bottomDivider = false,
  jobId = '',
}) => {
  const [fullTagsObj, setFullTagsObj] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [fulfilmentId, setFulfilmentId] = useState<string>(jobId);

  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const { data: dataFulfilmentId, isInitialLoading: isLoadingJobId } =
    useQuery<any>({
      queryKey: [queryNames.FULFILMENT_CONTENTS_GET, 'fulfilmentId', contentId],
      meta: {
        token,
        getAllData,
        additionalSearchParams: `?id=${contentId}&with_deleted=true&columns=fulfilment_id`,
      },
      queryFn: useGetData,
      enabled: !jobId,
    });

  const {
    data,
    isLoading: isLoadingTags,
    isFetching: isFetchingTags,
    isError,
    refetch,
  } = useQuery<any>({
    queryKey: [queryNames.FULFILMENTS, fulfilmentId + 'meta'],
    meta: {
      token,
      getAllData,
      columnParams: ['metadata'],
      additionalSearchParams: `?id=${fulfilmentId}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!fulfilmentId,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (onSuccessData) => {
      if (onSuccessData && onSuccessData.hasOwnProperty('error')) {
        message.error(`Server Error ${onSuccessData.error?.message}`);
        return;
      } else {
        message.success('Tags was successfully updated');
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (dataFulfilmentId && dataFulfilmentId?.data?.fulfilment_id) {
      setFulfilmentId(dataFulfilmentId.data.fulfilment_id);
    }
  }, [dataFulfilmentId]);

  useEffect(() => {
    if (
      !isFetchingTags &&
      data &&
      Array.isArray(data?.data) &&
      data.data.length
    ) {
      const { metadata } = data.data[0];
      if (!metadata) return;
      const tagsObj: { [key: string]: string[] } = metadata?.tags;
      if (!tagsObj) return;
      const filteredByIdTags = [];
      for (const [key, value] of Object.entries(tagsObj)) {
        value.includes(contentId) && filteredByIdTags.push(key);
      }
      setTags(filteredByIdTags);
      setFullTagsObj(tagsObj);
    }
  }, [contentId, data, isFetchingTags]);

  const updateTagsMutation = useCallback(
    (updatedTags: { [key: string]: string[] }) => {
      const newMeta: MetadataT = { ...data.data[0].metadata };
      newMeta.tags = updatedTags;
      const mutateData = {
        id: fulfilmentId,
        metadata: newMeta,
      };

      mutate({
        data: mutateData,
        token,
        otherProps: queryNames.FULFILMENTS,
        method: 'PUT',
      });
    },
    [data, fulfilmentId, mutate, token]
  );

  const handleChange = (value: string[]) => {
    setTags(value);
    if (value.length) {
      const newFullTagsObj = { ...(fullTagsObj || {}) };
      for (const [key, tagsList] of Object.entries(newFullTagsObj)) {
        if (value.includes(key)) {
          !tagsList.includes(contentId) && tagsList.push(contentId);
        } else {
          tagsList.includes(contentId) &&
            tagsList.splice(tagsList.indexOf(contentId), 1);
        }
      }
      setFullTagsObj(newFullTagsObj);
      updateTagsMutation(newFullTagsObj);
    }
  };

  const tagRender: TagRender = (props) => {
    const { label, value } = props;
    if (!value) {
      return (
        <Tag className={styles.tagMore} color="#ebf0f3">
          {label}
        </Tag>
      );
    }
    return (
      <Tag
        className={styles.tag}
        title={value.length > 15 ? value : undefined}
        bordered
      >
        {value.length > 15 ? `${value.substring(0, 14)}...` : value}
      </Tag>
    );
  };

  return isError ? (
    <Tag color="error">Error loading tags</Tag>
  ) : (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {topDivider && (
        <Divider
          style={{ margin: '10px 0', borderColor: '#D5DBDF' }}
          orientation="left"
          plain
        >
          Tags
        </Divider>
      )}
      <div className={styles.newWrapper}>
        <Select
          disabled={Object.keys(fullTagsObj || {}).length === 0}
          loading={isLoadingTags || isLoading || isLoadingJobId}
          placeholder={
            Object.keys(fullTagsObj || {}).length
              ? 'Select tag'
              : 'No tags in Job'
          }
          size="small"
          style={{ width: '100%' }}
          mode="multiple"
          maxTagCount={'responsive'}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{ pointerEvents: 'none' }}
              title={omittedValues.map(({ label }) => label).join(', ')}
            >
              <span>+{omittedValues.length} more</span>
            </Tooltip>
          )}
          tagRender={tagRender}
          value={tags}
          options={Object.keys(fullTagsObj || {}).map((tag) => ({
            label: tag,
            value: tag,
          }))}
          onChange={handleChange}
        />
      </div>
      {bottomDivider && (
        <Divider style={{ margin: '10px 0', borderColor: '#D5DBDF' }} />
      )}
    </div>
  );
};
