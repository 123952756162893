import { EditTwoTone } from '@ant-design/icons';
import { Button, CheckboxOptionType, Radio, Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { queryNames } from 'api/queryNames';
import { FC, useState } from 'react';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';
import { RoleEditT } from './types';

export const RoleEdit: FC<RoleEditT> = ({
  accountId,
  userId,
  value,
  token,
  mutate,
  rolesData,
}) => {
  const [showRoleRadio, setShowRoleRadio] = useState<boolean>(false);

  const changeRole = (newValue: string) => {
    mutate({
      data: {
        account_id: accountId,
        role: newValue,
        user_id: userId,
      },
      token,
      url: `${APP_CONSTANS.BASE_API_URL}/${queryNames.USER_ACCOUNTS}`,
      method: 'PATCH',
    });
  };

  const role = (
    <Space key={`${userId}${accountId}btn`}>
      <span className={styles.capitalizeText}>{value}</span>
      <Button
        type="text"
        icon={<EditTwoTone twoToneColor="#0091b5" />}
        onClick={() => setShowRoleRadio(true)}
      />
    </Space>
  );
  const radio = (
    <Radio.Group
      key={`${userId}${accountId}radio`}
      className={styles.radioGroup}
      options={rolesData as CheckboxOptionType<CheckboxValueType>[] | []}
      onChange={(e) => changeRole(e.target.value)}
      value={value}
      size="small"
      optionType="button"
      buttonStyle="solid"
    />
  );
  return showRoleRadio ? radio : role;
};
